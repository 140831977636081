import React, { Fragment } from "react"
import { GATSBY_MH_WEBSITE_CODE } from "gatsby-env-variables"
import PropTypes from "prop-types"
import Zendesk from "react-zendesk"
import classNames from "classnames"

import SEO from "./SEO"
import Container from "./Container"
import Navbar from "./Navbar"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import PrivateRoute from "./PrivateRoute"

import zendeskSettings from "./utils/zendeskSettings.json"
import { hasSignedInUser } from "../Auth/services/user"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true },
  path,
  isPrivate,
  pageContext,
}) => {
  let pathArray = path ? path.split("/") : []

  return (
    <Fragment>
      <PrivateRoute isPrivate={isPrivate}>
        <SEO title={seoTitle} />
        <Navbar path={pathArray} />
        <Notification pageContext={pageContext} />

        {title && (
          <Container isCentered>
            <h2
              className={classNames(
                "has-text-centered mb-0 has-text-primary mx-2-mobile is-size-3-mobile",
                {
                  "pb-0": !subtitle,
                  "pb-2": !!subtitle,
                }
              )}
            >
              {title}
              <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-4 mx-1-mobile is-size-5-mobile">
                {subtitle}
              </h4>
            </h2>
          </Container>
        )}

        <main className="mb-3">{children}</main>
        {display.helpCenterBanner && (
          <HelpCenterBanner module={pageContext?.module?.name} />
        )}
        {display.footer && <Footer />}
        {hasSignedInUser() &&
          pageContext?.module?.name === GATSBY_MH_WEBSITE_CODE && (
            <Zendesk
              zendeskKey={process.env.GATSBY_ZENDESK_MH_CHAT_ID}
              {...zendeskSettings}
            />
          )}
      </PrivateRoute>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
