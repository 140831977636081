import React, { Fragment } from "react"
import Loading from "elements/Loading"
import { navigate } from "gatsby"
import { isBrowser } from "../../services/general"
import { hasRoles, hasSignedInUser } from "../Auth/services/user"

const PrivateRoute = ({ isPrivate, children }) => {
  if (isPrivate && isBrowser()) {
    if (hasSignedInUser() && !hasRoles()) {
      navigate("/enrollment")
    }
    if (!hasSignedInUser()) {
      navigate("/verify-email")
      return <Loading />
    }
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute
