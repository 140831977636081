import React from "react"

const MED_DEFAULT_BANNER = (
  <span>
    Search for your medicines{" "}
    <a target="_blank" rel="noreferrer noopener" href="/med/#search-meds">
      here
    </a>{" "}
    or email your prescription to{" "}
    <a
      target="_blank"
      rel="noreferrer noopener"
      href="mailto:concentrix-med@medgrocer.com"
    >
      concentrix-med@medgrocer.com
    </a>
  </span>
)

const MIND_DEFAULT_BANNER = null

export const defaultBanners = {
  med: MED_DEFAULT_BANNER,
  mind: MIND_DEFAULT_BANNER,
}
